export default function initializeAPI() {
  let createAPIKeyForm;

  function handleFormSubmit(event) {
      event.preventDefault();
      fetch(event.target.action, {
          method: 'POST',
          body: new FormData(event.target),
          headers: {
              'X-Requested-With': 'XMLHttpRequest'
          }
      })
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
          console.log('AJAX response:', data);

          const apiKeyInput = document.getElementById('apiKey');
          apiKeyInput.disabled = false;
          apiKeyInput.value = data.api_key;
          apiKeyInput.disabled = true;

          bootstrap.Modal.getInstance(document.getElementById('generateAPIKey')).hide();
          new bootstrap.Modal(document.getElementById('resultModal')).show();
      })
      .catch(error => {
          console.error('Error:', error);
          alert('An error occurred while generating the API key. Please try again.');
      });
  }

  function attachEventListeners() {
      createAPIKeyForm = document.getElementById('createAPIkeyForm');
      
      if (createAPIKeyForm) {
          createAPIKeyForm.addEventListener('submit', handleFormSubmit);
      }
  }

  function cleanup() {
      if (createAPIKeyForm) {
          createAPIKeyForm.removeEventListener('submit', handleFormSubmit);
      }
  }

  function handleAfterSwap() {
      cleanup();
      attachEventListeners();
  }

  function handleDOMContentLoaded() {
      attachEventListeners();
  }

  // Add event listeners
  document.addEventListener('htmx:afterSwap', handleAfterSwap);
  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  // Initial setup
  attachEventListeners();

  // Return an object with cleanup function and a reinitialize function
  return {
      cleanup: cleanup,
      reinitialize: attachEventListeners
  };
}